<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('set.title')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <van-row class="spacing">
                <van-row>
                    <!-- <van-cell center :title="$t('set.phone')" is-link @click="setphoneshow">
                        <template #default>
                            <span v-if="setinfo.phone">{{ $t('set.bind') }}</span>
                            <span v-else>{{ $t('set.unbind') }}</span>
                        </template>
                    </van-cell> -->
                    <van-popup class="setting" v-model="setphone">
                        <h3>{{ $t('set.setphone') }}</h3>
                        <van-row class="text">
                            <van-cell-group>
                                <van-field v-model="phone" center clearable :placeholder="$t('set.notice1')">
                                    <template #left-icon>
                                        <span>{{ $t('set.phone') }}</span>
                                    </template>
                                    <template #label>
                                        <span @click="quhaoshow"
                                            style="margin-left: 10px; color: rgb(128, 128, 128);">+{{ select_quhao }}
                                            <van-icon name="arrow-down" style="font-size: 7px;" />
                                        </span>
                                        <van-popup class="setting" style="height: 60%; padding: 10px;" v-model="quhao">
                                            <van-row v-for="qh in codelist" :key="qh.id" @click="selectqh(qh.code)">
                                                <van-cell :title="current === 'en' ? qh.enname : qh.cnname"
                                                    :value="'+' + qh.code" />
                                            </van-row>
                                        </van-popup>
                                    </template>
                                </van-field>
                                <van-field v-model="smscode" center clearable :label="$t('set.code')"
                                    :placeholder="$t('set.notice3')">
                                    <template #button>
                                        <span v-if="sendsmsbtn"
                                            style="font-size: 15px; border: none; color: rgb(66, 185, 131);"
                                            @click="sendsms">{{ $t('set.send') }}</span>
                                        <span v-else>{{ sendsmstime }}s</span>
                                    </template>
                                </van-field>
                            </van-cell-group>
                        </van-row>
                        <!-- 按钮 -->
                        <button style="border-right: 1px solid white;" @click="close">
                            {{ $t('common.cancel') }}
                        </button>
                        <button @click="bindphone">{{ $t('common.confirm') }}</button>
                    </van-popup>
                    <van-cell center :title="$t('set.email')" is-link @click="setemailshow"><template #default>
                            <span v-if="setinfo.email">{{ $t('set.bind') }}</span>
                            <span v-else>{{ $t('set.unbind') }}</span>
                        </template>
                    </van-cell>
                    <van-popup class="setting" v-model="setemail">
                        <h3>{{ $t('set.setemail') }}</h3>
                        <van-row class="text">
                            <van-cell-group>
                                <van-field v-model="email" center clearable :label="$t('set.email')"
                                    :placeholder="$t('set.notice2')" />
                                <van-field v-model="emailcode" center clearable :label="$t('set.code')"
                                    :placeholder="$t('set.notice3')">
                                    <template #button>
                                        <span v-if="sendemailbtn"
                                            style="font-size: 15px; border: none; color: rgb(66, 185, 131);"
                                            @click="sendemail">{{ $t('set.send') }}</span>
                                        <span v-else>{{ sendemailtime }}s</span>
                                    </template>
                                </van-field>
                            </van-cell-group>
                        </van-row>
                        <!-- 按钮 -->
                        <button style="border-right: 1px solid white;" @click="closeemail">
                            {{ $t('common.cancel') }}
                        </button>
                        <button @click="bindemail">{{ $t('common.confirm') }}</button>
                    </van-popup>
                    <van-cell center :title="$t('set.nickname')" is-link @click="setnicknameshow"><template #default>
                            <span v-if="setinfo.nickname">{{ $t('set.bind') }}</span>
                            <span v-else>{{ $t('set.unbind') }}</span>
                        </template>
                    </van-cell>
                    <van-popup class="setting" style="width:90%" v-model="setnickname">
                        <h3>{{ $t('set.setnick') }}</h3>
                        <van-row class="text">
                            <van-cell-group>
                                <van-field v-model="nickname" center clearable :label="$t('set.nickname')"
                                    :placeholder="$t('set.notice4')" />
                            </van-cell-group>
                        </van-row>
                        <!-- 按钮 -->
                        <button style="border-right: 1px solid white;" @click="closenick">
                            {{ $t('common.cancel') }}
                        </button>
                        <button @click="bindnick">{{ $t('common.confirm') }}</button>
                    </van-popup>
                    <van-cell center :title="$t('set.editpass')" is-link to="/person/setting/changepassword" />
                    <van-cell center :title="$t('set.contact')" is-link to="/person/setting/bank" />
                </van-row>
                <van-row style="margin-top:10px">
                    <van-cell center :title="$t('set.lang')">
                        <template #extra>
                            <van-dropdown-menu active-color="#004ea3">
                                <van-dropdown-item v-model="current" :options="lang" @change="change" />
                            </van-dropdown-menu>
                        </template>
                    </van-cell>
                    <van-cell center :title="$t('set.version')" value="V2.2.4" />
                </van-row>
            </van-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            setinfo: {
                nickname: '',
                phone: '',
                email: ''
            },
            // 手机号弹层
            setphone: false,
            phone: '',
            smscode: '',
            sendsmsbtn: true,
            sendsmstime: 60,
            quhao: false,
            codelist: [],
            // 选择的区号
            select_quhao: '',
            // 邮箱弹层
            setemail: false,
            email: '',
            emailcode: '',
            sendemailbtn: true,
            sendemailtime: 60,
            // 昵称弹窗
            setnickname: false,
            nickname: '',
            // 语言选择
            current: localStorage.getItem('lang') || 'en',
            lang: [
                { text: '中文', value: 'zh' },
                { text: 'English', value: 'en' },
                { text: 'русский язык', value: 'ru' },
                { text: 'Français', value: 'fr' },
                { text: 'Español', value: 'es' },
                { text: 'Italiano', value: 'it' }
            ],
            eaminTimer: null,
            smsTimer: null
        };
    },
    created() {
        this.getinfo();
    },
    methods: {
        // 获取信息
        async getinfo() {
            const { data } = await this.$http.get('/home/home/getsetinfo');
            if (data) {
                if (data.code === 200) {
                    this.setinfo = data.data;
                }
            }
        },
        // 设置手机号
        // async setphoneshow() {
        //   this.phone = this.setinfo.phone
        //   this.setphone = true
        //   const { data } = await this.$http.get('/home/home/getcodelist')
        //   if (data) {
        //     if (data.code === 200) {
        //       this.codelist = data.data
        //     }
        //   }
        // },
        // //区号
        quhaoshow() {
            this.quhao = true;
        },
        // //选择区号
        selectqh(val) {
            this.select_quhao = val;
            this.quhao = false;
        },
        // //发送手机验证码
        async sendsms() {
            if (!this.phone) {
                this.$toast.fail(this.$t('set.notice1'));
                return false;
            }
            if (this.sendsmsbtn === false) {
                return false;
            }
            const { data } = await this.$http.post('/home/home/sendsms', {
                phone: this.phone,
                area: this.select_quhao
            });
            if (data) {
                if (data.code === 200) {
                    this.sendsmsbtn = false;
                    this.sendsmstime = 60;
                    this.$toast.success(this.$t('set.codesent'));
                    this.smsTimer = setInterval(() => {
                        this.sendsmstime--;
                        if (this.sendsmstime <= 0) {
                            this.sendsmsbtn = true;
                            clearInterval(this.smsTimer);
                        }
                    }, 1000);
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
                }
            }
        },
        // //关闭
        close() {
            this.phone = '';
            this.smscode = '';
            this.setphone = false;
        },
        // //确定绑定手机
        async bindphone() {
            if (!this.phone || !this.smscode) {
                this.$toast.fail(this.$t('set.notice1'));
                return false;
            }
            const { data } = await this.$http.post('/home/home/bindphone', {
                phone: this.phone,
                smscode: this.smscode
            });
            if (data) {
                if (data.code === 200) {
                    this.$toast.success(this.$t('set.bind'));
                    this.setphone = false;
                    this.getinfo();
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
                }
                clearInterval(this.smsTimer);
            }
        },
        // 设置邮箱
        setemailshow() {
            this.email = this.setinfo.email;
            this.setemail = true;
        },
        // //发送邮箱验证码
        async sendemail() {
            if (!this.email) {
                this.$toast.fail(this.$t('set.notice2'));
                return false;
            }
            if (
                !/^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
                    this.email
                )
            ) {
                this.$toast.fail(this.$t('set.emailformat'));
                return false;
            }
            if (this.sendemailbtn === false) {
                return false;
            }
            const { data } = await this.$http.post('/home/home/sendemail', {
                email: this.email
            });
            if (data) {
                if (data.code === 200) {
                    this.sendemailbtn = false;
                    this.sendemailtime = 60;
                    this.$toast.success(this.$t('set.codesent'));
                    this.eaminTimer = setInterval(() => {
                        this.sendemailtime--;
                        if (this.sendemailtime <= 0) {
                            this.sendemailbtn = true;
                            clearInterval(this.eaminTimer);
                        }
                    }, 1000);
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
                }
            }
        },
        // //关闭
        closeemail() {
            this.email = '';
            this.emailcode = '';
            this.setemail = false;
        },
        // //确定绑定邮箱
        async bindemail() {
            if (!this.email || !this.emailcode) {
                this.$toast.fail(this.$t('set.notice2'));
                return false;
            }
            const { data } = await this.$http.post('/home/home/bindemail', {
                email: this.email,
                emailcode: this.emailcode
            });
            if (data) {
                if (data.code === 200) {
                    this.$toast.success(this.$t('set.bind'));
                    this.setemail = false;
                    this.email = '';
                    this.emailcode = '';
                    this.getinfo();
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
                }
                clearInterval(this.eaminTimer);
            }
        },
        // 设置昵称
        setnicknameshow() {
            if (this.setinfo.nickname) {
                this.$toast.fail(this.$t('set.nicktoast'));
                return false;
            }
            this.setnickname = true;
        },
        // //关闭
        closenick() {
            this.nickname = '';
            this.setnickname = false;
        },
        // //确定绑定邮箱
        async bindnick() {
            if (!this.nickname) {
                this.$toast.fail(this.$t('set.notice4'));
                return false;
            }
            const { data } = await this.$http.post('/home/home/bindnickname', {
                nickname: this.nickname
            });
            if (data) {
                if (data.code === 200) {
                    this.$toast.success(this.$t('set.bind'));
                    this.setnickname = false;
                    this.getinfo();
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
                }
            }
        },
        // 切换语言
        change(val) {
            if (this.$i18n.locale === 'zh') {
                this.$i18n.locale = 'en';
                localStorage.setItem('lang', 'en');
            } else {
                this.$i18n.locale = 'zh';
                localStorage.setItem('lang', 'zh');
            }
        }
    }
};
</script>
<style lang="less" scoped>
.maincontent {
    padding-top: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    background: #f7f7f7;
    height: 100vh;

    .spacing {
        margin-top: 1.2rem;

        .van-cell {
            height: 3.2rem;
            font-size: 0.93333rem;
        }

        .van-cell__title {
            text-align: left;
        }

        .van-cell__value {
            color: #000;
        }

        .van-dropdown-menu /deep/.van-dropdown-menu__bar {
            box-shadow: none;
            height: 1rem;
            background: transparent !important;
        }

        .van-dropdown-menu /deep/ .van-dropdown-menu__title {
            color: #212121 !important;
        }

        /deep/.van-dropdown-item {
            margin-top: 1.1rem;
        }

        // 弹层
        .setting {
            width: 75%;
            border-radius: 0.4rem;
            color: #004ea3 !important;

            [class*='van-hairline']:after {
                border: none;
            }

            h3 {
                padding-top: 4%;
                padding-bottom: 4%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                font-size: 1rem;
                border-bottom: 0.02667rem solid #e5e5e5;
            }

            .text {
                width: 100%;
                height: 55%;
                border: 0;

                .van-cell-group {
                    background-color: #fff;
                    margin-top: 1.33333rem;
                    margin-bottom: 1.33333rem;

                    .van-cell {
                        font-size: 0.93333rem;
                    }

                    .van-field__left-icon {
                        margin-right: 4px;
                    }

                    /deep/.van-field__label {
                        width: 4.2rem;
                        margin-right: 0.13333rem;
                    }

                    /deep/.van-cell__value {
                        color: #000;
                    }

                    /deep/.van-field input {
                        color: #000;
                    }

                    /deep/.van-field__control {
                        font-size: 1rem !important;
                        border-radius: 0.08rem !important;
                    }
                }
            }

            button {
                width: 50%;
                padding-top: 4%;
                padding-bottom: 4%;
                border: 0;
                background-color: #fff;
                border-top: 0.02667rem solid #e5e5e5;
                color: #004ea3;
                font-size: 1.06667rem;
            }
        }
    }
}
</style>
